import React, { useEffect } from "react";
import { Section, ColumnRight } from "./Feature.styles";
import AOS from 'aos';
import "aos/dist/aos.css";


const Feature = ({ heading, image, width = "60%" }) => {

    useEffect(() => {
        AOS.init({
            duration: 3000
        });
        AOS.refresh();
    }, []);


    return (
        <Section>
            <h2>{heading}</h2>
            <ColumnRight
                width={width} data-aos="zoom-in-down"
            >
                <img src={image} alt='home' height="420" />
            </ColumnRight>

        </Section>
    )
}

export default Feature;


import React, { useEffect, useLayoutEffect, useState } from "react";
import { Section, Container, ColumnLeft, ColumnRight, Button } from "./Content.styles";
import AOS from 'aos';
import "aos/dist/aos.css";


const Content = ({ heading, paragraphOne, paragraphTwo, buttonLabel, reverse, image, aos, videoPresent }) => {

    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 3000
        });
        AOS.refresh();
    }, []);
    const imgWidth = size > 767 ? (size - 200) / 2 : size - 32;
    return (
        <Section>
            <Container>
                <ColumnLeft data-aos="fade-up" >
                    <h1>{heading}</h1>
                    <p>{paragraphOne}</p>
                    <p>{paragraphTwo}</p>
                    <Button to='/' primary='true'>{buttonLabel}</Button>
                </ColumnLeft>
                <ColumnRight reverse={reverse} data-aos={aos}>
                    {!videoPresent ? (<img src={image} alt='home' />) : (
                        <video width={imgWidth} height="auto" autoPlay loop muted >
                            <source src={image} type="video/mp4" />
                        </video>)}
                </ColumnRight>
            </Container>
        </Section>
    )
}

export default Content;


import ImageOne from '../assests/image/content.png';



const ContentData = {
    heading: 'Convert Your Electric Motorcycles With the Longest Range Battery',
    paragraphOne: 'RECELLS presents the rechargeable battery which is the power source of electric two-wheeler and provides the fuel (electricity) to the electric motor.',
    paragraphTwo: `E- bike that can run upto 200km on a single charge ― "RECELLS" equipped with energy dense NMC811 and High Number of Charge/Dischage Cycles Life LiFePO4 batteries.`,
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: false,
    aos: 'zoom-in-left',
    delay: 100,
    videoPresent: false,
};

export default ContentData;


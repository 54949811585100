import React, { useLayoutEffect, useState } from 'react';
import VideoPlayer from "react-background-video-player";
import "./VideoContainer.css"
import { VideoWrapper, InsideContainer, MainHeadingDesktop, SubHeadingDesktop, InsideContainerContentDesktop, InsideContainerContentMobile, MainHeadingMobile, SubHeadingMobile, Button } from './VideoContainer.styles';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


const VideoContainer = () => {

    const [width, height] = useWindowSize();
    console.log(width, height);

    return (
        <VideoWrapper>
            <VideoPlayer
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                className="video"
                src={require('../../assests/video/v3.mp4')}
                autoPlay={true}
                muted={true}
            />

            {(width > 425) ? (
                <InsideContainer>
                    <InsideContainerContentDesktop>
                        <MainHeadingDesktop>
                            The Future of e-mobility,
                        </MainHeadingDesktop>
                        <SubHeadingDesktop>Introducing Lithium-Batteries by RECELLS</SubHeadingDesktop>
                        <Button to='/Contact' primary='true'>Contact Us</Button>
                    </InsideContainerContentDesktop>
                </InsideContainer>) :

                (<InsideContainerContentMobile>
                    <MainHeadingMobile>
                        The Future of e-battery,
                    </MainHeadingMobile>
                    <SubHeadingMobile>Introducing lithium-batteries by RECELLS</SubHeadingMobile>
                    <Button to='/Contact' primary='true'>Contact Us</Button>
                </InsideContainerContentMobile>)}

        </VideoWrapper>
    )
}

export default VideoContainer;

import React from 'react';
import client1 from '../assests/image/about.png';
import styled from 'styled-components/macro';
import '../index.css';

const Main = styled.div`
 background-color: #000;
`;

const MainContent = styled.div`
 width: 100%;
 display: block;
 max-width: 1100px; 
 grid-gap: 8px;
 margin: 0 auto;
 color: #fff;
 padding: 40px 45px;

@media screen and (max-width: 728px) {
    {
        display:block;
        padding:15px 45px;
    }
}
h2{
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 22px;
}

p{
    margin-top:35px;
    margin-bottom: 0px;
    text-align:justify;
}

img{
    
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;

    @media screen and (max-width: 728px) {
    {
        margin:0px;
        width: 100%;
    }
}
}   
`;

const About = () => {
    return (
        <>
            <Main>
                <MainContent>
                    <div>
                        <h2>ABOUT US</h2>
                        <img src={client1} width="400" alt="Natural" />
                        <p>Among the list of Lithium-ion battery manufacturers in India, established in 21st century, RECELLS TECHNOLOGY is located at Surat, Gujarat. With our aim to convert RECELLS BATTERIES into a world-class Lithium Battery Unit, our diligent team focuses on enhancing the facilities, infusing financial stability, and integrating modern technology.
                        </p>
                        <br />
                        <h4>Why Us?</h4>
                        <p>Premium quality<br />
                            3 Year warranty<br />
                            Professional manufacturer<br />
                            Cost Effective<br />
                            Reliable customer service<br />
                        </p>
                        <p>
                            We provide a wide range of customized solutions for Lithium Batteries, Lithium-ion Cells, Battery Packs for electric vehicles like e Scooter battery, e Cycle battery and more, solar lighting & energy storage, medical & other devices. Our Lithium solutions are based on NMC and LFP chemistries providing energy up to 10 kWh & extendable for a plethora of applications like portable storage devices, UPS batteries, Hybrid Inverter Batteries, Power Banks, etc.
                        </p>
                        <p>Currently, we are working on conversion of Maruti EECO to electric and succesfully accomplised 2 tests. We are at the R&D stage  and will launch the EV Conversion Kit after complete testing, safety checks and verification of the real range and top speed. We have collaborated with a Maruti Authorized Service Station in Surat for retrofitting of the conversion kit.</p>
                    </div>
                </MainContent>
            </Main>
        </>
    )
}

export default About;

import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import { NavLink, Link } from 'react-router-dom';
import '../../index.css';

const Footer = () => {
    const executeScroll = () => {
        document.getElementById('myHeaderDiv').scrollIntoView();
    }
    return (
        <MDBFooter bgColor='black' className='text-center text-lg-start text-muted' color='white'>
            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom ftop'>
                <div className='me-5 d-none d-lg-block'>
                    <span>Get connected with us on social networks:</span>
                </div>

                <div>
                    <a href="https://www.facebook.com/recellstechnology" target="_blank" className='me-4 text-reset'>
                        <i className='fab fa-facebook-f'></i>
                    </a>
                    <a href="https://g.page/r/CUB-MVI-rxiqEA0" target="_blank" className='me-4 text-reset'>
                        <i className='fab fa-google'></i>
                    </a>
                    <a href="https://www.instagram.com/recells_technology" target="_blank" className='me-4 text-reset'>
                        <i className='fab fa-instagram'></i>
                    </a>
                    <a href="https://www.linkedin.com/company/recells-technology/" target="_blank" className='me-4 text-reset'>
                        <i className='fab fa-linkedin'></i>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=919624778593&text=Hello" target="_blank" className='me-4 text-reset'>
                        <i className='fab fa-whatsapp'></i>
                    </a>
                </div>
            </section>

            <section className='fbottom'>
                <div className='container text-center text-md-start mt-5'>
                    <div className='row mt-3'>
                        <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>
                                <i className='fas fa-gem me-3'></i>RECELLS TECHNOLOGY
                            </h6>
                            <p>
                                Lithium batteries keep going and going and going and going...
                            </p>
                        </div>

                        <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                            <p>
                                <NavLink to='/About' className='text-reset' onClick={executeScroll}>
                                    About us
                                </NavLink>
                            </p>
                            <p>
                                <NavLink to='/Contact' className='text-reset' onClick={executeScroll}>
                                    Contact Us
                                </NavLink>
                            </p>
                            <p>
                                <NavLink to='/TermsService' className='text-reset' onClick={executeScroll}>
                                    Terms of Service
                                </NavLink>
                            </p>
                        </div>

                        <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                            <p>
                                <i className='fas fa-home me-3'></i>62-67, BAJRANGNAGAR, SHAKTIVIJAY, NANA VARACHHA, SURAT- 395006. GUJARAT, INDIA.
                            </p>
                            <p>
                                <i className='fas fa-envelope me-3'></i>
                                recellstechnology@gmail.com
                            </p>
                            <p>
                                <i className='fas fa-phone me-3'></i>+91 96247 78593
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: '#fff' }}>
                © 2022 Copyright:
                <NavLink className='text-reset fw-bold' to=''>
                    RECELLS TECHNOLOGY
                </NavLink>
            </div>
        </MDBFooter>
    );
}

export default Footer;
import image1 from "../../assests/image/new.png";
import image2 from "../../assests/image/new2.png";
import image3 from "../../assests/image/new3.png";
import image4 from "../../assests/image/new4.png";

export const photo = [
    {
        src: image1,
        content: '300 Kg Load Bearing Capacity'
    },
    {
        src: image2,
        content: '300 Kg Load Bearing Capacity'
    },
    {
        src: image3,
        content: '300 Kg Load Bearing Capacity'
    },
    {
        src: image4,
        content: '300 Kg Load Bearing Capacity'
    },
];

import ImageOne from '../assests/image/ts.png';


const FeatureData1 = {
    heading: 'Riding With Friends Is More Fun - Share With Them!',
    image: ImageOne,
};

export default FeatureData1;


import ImageOne from '../assests/image/mobile.jpg';


const ContentData4 = {
    heading: 'We are offering wide range of Electric Rickshaw Lithium Battery to our client as per their requirement.',
    paragraphOne: 'These products are designed using supreme grade material and latest technology with the set norms.',
    paragraphTwo: 'We are involved in providing a wide variety of EV Batteries for our valued patrons.',
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: true,
    aos: 'fade-right',
    delay: 100,
    videoPresent: false,
};

export default ContentData4;


const MenuData = [

    { title: 'HOME', link: '/' },
    { title: 'ABOUT US', link: '/About' },
    { title: 'TERMS OF SERVICE', link: '/TermsService' },
    { title: 'CONTACT US', link: '/Contact' },

]

const MenuDataDrawer = [
    { title: 'HOME', link: '/' },
    { title: 'ABOUT US', link: '/About' },
    { title: 'TERMS OF SERVICE', link: '/TermsService' },
    { title: 'CONTACT US', link: '/Contact' },

]

export { MenuDataDrawer, MenuData };
import ImageOne from '../assests/image/EBike.mp4';


const ContentData2 = {
    heading: 'Hi-Tech E-Bike Battery',
    paragraphOne: 'RECELLS batteries are an efficient, long-life energy source. Thanks to their enormous mileage, long service life and intelligent Battery Management System (BMS), RECELLS lithium-ion batteries are among the most modern on the market. RECELLS e-Bike rechargeable batteries combine low weight with ergonomic design and simple handling. The Battery Management System of the high-quality lithium-ion batteries detects potential sources of error and protects cells from over charging and discharging. There is a suitable rechargeable battery for every requirement and every type of e-Bike, from the rack variant, via the frame battery, to the integrated solution. The Dual-Battery is perfect for touring e-Bikers, long-distance commuters or cargo bikers.',
    paragraphTwo: '',
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: false,
    aos: 'zoom-in-left',
    delay: 100,
    videoPresent: true,
};

export default ContentData2;


import ImageOne from '../assests/image/InverterBattery.mp4';


const ContentData5 = {
    heading: 'Solar Off-Grid / Hybrid Inverter Lithium Battery',
    paragraphOne: 'We offer customized lithium battery with Smart Battery Management System which is specifically designed as per compatibility of Inverter. The self-discharge rate is very low and no. of charge-discharge cycles is super high in comparison with commercial Tubular Lead-Acid Batteries.',
    paragraphTwo: '',
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: false,
    aos: 'zoom-in-left',
    delay: 100,
    videoPresent: true,
};

export default ContentData5;


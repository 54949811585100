import ImageOne from '../assests/image/EBicycle.mp4';


const ContentData3 = {
    heading: 'E-Bicycle Battery',
    paragraphOne: 'These products are designed and developed by making use of pristine-class quality basic material and highly advance tools under the command of accomplished & well-informed personnel.',
    paragraphTwo: '',
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: true,
    aos: 'zoom-in-left',
    delay: 100,
    videoPresent: true,
};

export default ContentData3;


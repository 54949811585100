import ImageOne from '../assests/image/map.png';


const ContentData1 = {
    heading: 'Go Farther with 125 Miles/ 117Kms Range',
    paragraphOne: 'On a single charge, the bike can take you from:',
    paragraphTwo: `1. Surat
                   2. Navsari
                   3. Valsad
                   4. Daman`,
    buttonLabel: 'View Homes',
    image: ImageOne,
    reverse: true,
    aos: 'zoom-in-left',
    delay: 100,
    videoPresent: false,
};

export default ContentData1;

